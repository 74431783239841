<template>
  <div style="display: flex; align-items: center">
    <span style="font-size: 20px; font-weight: 500; color: #ffffff"
      >{{ lable }}：</span
    >
    <div
      :class="['value', `${type}-value`]"
      :style="`letter-spacing: ${border ? 0 : 3}px;`"
    >
      <template v-if="border">
        <span
          v-for="(e, i) in value"
          :key="i"
          style="
            background: rgba(60, 202, 255, 0.1);
            border: 1px solid;
            border-radius: 4px;
            padding: 6px;
            margin-right: 6px;
          "
          >{{ e }}</span
        >
      </template>
      <template v-else>{{ value }}</template>
    </div>
  </div>
</template>

<script>
export default {
  //组件注册
  components: {},
  //组件传值
  props: {
    type: {
      type: String,
      default: 'cyan',
    },
    lable: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped>
.value {
  font-size: 40px;
  font-weight: bold;
}
.cyan-value {
  color: #05fffc;
}
.yellow-value {
  color: #ffcc1b;
}
.red-value {
  color: #fe1329;
}
</style>
